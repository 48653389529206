$i: 0;
@while $i <= 30 {
    .pa-#{$i} {
        padding: $i * 1px !important;
    }
    .px-#{$i} {
        padding-left: $i * 1px !important;
        padding-right: $i * 1px !important;
    }
    .py-#{$i} {
        padding-top: $i * 1px !important;
        padding-bottom: $i * 1px !important;
    }
    .pl-#{$i} {
        padding-left: $i * 1px !important;
    }
    .pr-#{$i} {
        padding-right: $i * 1px !important;
    }
    .pt-#{$i} {
        padding-top: $i * 1px !important;
    }
    .pb-#{$i} {
        padding-bottom: $i * 1px !important;
    }
    $i: $i + 1;
}

$i: 0;
@while $i <= 30 {
    .mx-#{$i} {
        margin-left: $i * 1px !important;
        margin-right: $i * 1px !important;
    }
    .my-#{$i} {
        margin-top: $i * 1px !important;
        margin-bottom: $i * 1px !important;
    }
    .ml-#{$i} {
        margin-left: $i * 1px !important;
    }
    .mr-#{$i} {
        margin-right: $i * 1px !important;
    }
    .mt-#{$i} {
        margin-top: $i * 1px !important;
    }
    .mb-#{$i} {
        margin-bottom: $i * 1px !important;
    }
    $i: $i + 1;
}

.ml-auto {
    margin-left: auto !important;
}

.mr-auto {
    margin-right: auto !important;
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}
.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}
