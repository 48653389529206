@import './styles/paddings.scss';

/* You can add global styles to this file, and also import other style files */
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
// @import '../node_modules/font-awesome/css/font-awesome.css';
@import '../node_modules/animate.css/animate.min.css';

// @import '~@swimlane/ngx-datatable/index.css';
// @import '~@swimlane/ngx-datatable/themes/bootstrap.scss';
// @import '~@swimlane/ngx-datatable/assets/icons.css';

@import '../node_modules/@swimlane/ngx-datatable/index.css';
@import '../node_modules/@swimlane/ngx-datatable/themes/material.scss';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';

/* // Main Inspinia CSS files */
@import './assets/styles/style.css';
@import '../node_modules/metismenu/dist/metisMenu.css';

@import "../node_modules/ngx-toastr/toastr.css";

// @import "../node_modules/quill/dist/quill.core.css";
// @import "../node_modules/quill/dist/quill.bubble.css";
@import "../node_modules/quill/dist/quill.snow.css";

.d-block {
    display: block !important;
}

// .datatable-body-row {
//     padding-top: 10px;
//     padding-left: 10px;
//     padding-right: 10px;
// }
.datatable-body-row:hover {
    background-color: #eee;
}

.font-weight-bold {
    font-weight: bold !important;
}


.ngx-datatable.material .datatable-body .datatable-body-row.datatable-row-even {
    background-color: #0000000d;
}

.p-relative {
    position: relative;
}
/**** Flex Start *******/
.d-flex {
    display: flex;
}
.justify-center {
    justify-content: center !important;
}
.justify-end {
    justify-content: end !important;
}
.align-items-center {
    align-items: center !important;
}
.flex-grow-1 {
    flex-grow: 1;
}
/**** Flex Ends *******/

.label-over {
    position: absolute;
    top: -8px;
    left: 5px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #fff;
    font-size: 11px;
    font-weight: normal;
}


.cursor-pointer {
    cursor: pointer;
}

